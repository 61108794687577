export const SELECTLOGFooterBottom = () => import('./footer/selectlog-footer-bottom');
export const SELECTLOGFooterPayments = () => import('./footer/selectlog-footer-payments');
export const SELECTLOGFastOrders = () => import('./cart/selectlog-fast-orders');
export const SELECTLOGSugestions = () => import('./cart/selectlog-sugestions');
export const SELECTLOGCartPromotionCodes = () => import('./cart/selectlog-cart-promotion-codes');
export const SELECTLOGProfileRegistrationContainer = () => import('./profile/selectlog-profile-registration-container');
export const SELECTLOGUserProfileLinksDesktop = () => import('./header/selectlog-user-profile-links/desktop');
export const SELECTLOGUserProfileLinksMobile = () => import('./header/selectlog-user-profile-links/mobile');
export const SELECTLOGProfileUpdateExpiredPassword = () =>
  import('./profile/selectlog-profile-update-expired-password');
export const SELECTLOGProfileAddressBookSummary = () => import('./profile/selectlog-profile-address-book-summary');
export const SELECTLOGProfileDetailsSummary = () => import('./profile/selectlog-profile-details-summary');
export const SELECTLOGProfileRecurrence = () => import('./profile/selectlog-profile-recurrence');
export const SELECTLOGProductDetailsContainer = () => import('./product/selectlog-product-product-details-container');
export const SELECTLOGProductName = () => import('./product/selectlog-product-name');
export const SELECTLOGProductPrice = () => import('./product/selectlog-product-price');
export const SELECTLOGProductVariantOptions = () => import('./product/selectlog-product-variant-options');
export const SELECTLOGProductQuantity = () => import('./product/selectlog-product-quantity');
export const SELECTLOGProductAddToCartButton = () => import('./product/selectlog-product-add-to-cart-button');
export const SELECTLOGProductInventoryStatus = () => import('./product/selectlog-product-inventory-status');
export const SELECTLOGProductAddToPurchaselist = () => import('./product/selectlog-product-add-to-purchase-list');
export const SELECTLOGProductProductSocialSharing = () => import('./product/selectlog-product-product-social-sharing');
export const SELECTLOGProductShortDescription = () => import('./product/selectlog-product-short-description');
export const SELECTLOGProductLongDescription = () => import('./product/selectlog-product-product-long-description');
export const SELECTLOGCheckoutSingleShippingDetails = () =>
  import('./checkout/selectlog-checkout-single-shipping-details');
export const SELECTLOGProfileSavedCardSummary = () => import('./profile/selectolog-profile-saved-card-summary');
export const SELECTLOGLogin = () => import('./login/selectlog-login');
export const SELECTLOGProfileResetPassword = () => import('./profile/selectlog-profile-reset-password');
export const SELECTLOGAccountsContactsRegistration = () => import('./profile/selectlog-accounts-contacts-registration');
export const SELECTLOGHeaderContainerDesktop = () => import('./header/selectlog-header-container/desktop');
export const SELECTLOGHeaderContainerMobile = () => import('./header/selectlog-header-container/mobile');
export const SELECTLOGCommonSearchBoxMobile = () => import('./common/selectlog-common-search-box-mobile');
export const SELECTLOGHeaderMiniCartDesktop = () => import('./header/selectlog-header-mini-cart/desktop');
export const SELECTLOGHeaderMiniCartMobile = () => import('./header/selectlog-header-mini-cart/mobile');
export const SELECTLOGProfileAccountDetails = () => import('./profile/selectlog-profile-account-details');
export const SELECTLOGProfileDetails = () => import('./profile/selectlog-profile-details');
export const SELECTLOGProfileEmailMarketingPreferencesSummary = () =>
  import('./profile/selectlog-profile-email-marketing-preferences-summary');
export const SELECTLOGDocumentOrganizationNotification = () => import('./common/selectlog-common-docs-notification');
export const SELECTLOGProfileDocumentOrganization = () => import('./profile/selectlog-profile-document-organization');
export const SELECTLOGCommonSearchBoxDesktop = () => import('./common/selectlog-common-search-box-desktop');
export const SELECTLOGProductListingDynamicProductList = () =>
  import('./product-listing/selectlog-product-listing-dynamic-product-list');
export const SELECTLOGProductResultPrice = () => import('./product-listing/selectlog-product-result-price');
export const SELECTLOGProfileCreatePurchaseList = () => import('./profile/selectlog-profile-create-purchase-list');
export const SELECTLOGProfilePurchaseListActionButtons = () =>
  import('./profile/selectlog-profile-purchase-list-action-buttons');
export const SELECTLOGProductProductDetailsContainer = () =>
  import('./product/selectlog-product-product-details-container');
export const SELECTLOGProfileAddressBook = () => import('./profile/selectlog-profile-address-book');
export const SELECTLOGProductProductVariantOptions = () => import('./product/selectlog-product-variant-options');
export const SELECTLOGCommonRecommendationsCarouselSettings = () =>
  import('./common/selectlog-common-recommendations-carousel-settings');
export const SELECTLOGCommonCartSugestions = () => import('./common/selectlog-common-cart-sugestions');
export const SELECTLOGCommonToastUpdatePasswordNotification = () =>
  import('./common/selectlog-common-toast-update-password-notification');
export const SELECTLOGHeaderCollectionNavigationDesktop = () =>
  import('./header/selectlog-header-collection-navigation-desktop');
export const SELECTLOGCollectionNavigation = () => import('./header/selectlog-collection-navigation');
export const SelectlogHeaderCarnaval = () => import('./header/selectlog-header-carnaval');
export const SELECTLOGCommonImageCarousel = () => import('./common/selectlog-common-image-carousel');
export const SELECTLOGCommonImage = () => import('./common/selectlog-common-image');
export const SELECTLOGProductListingDynamicContainer = () =>
  import('./product-listing/selectlog-product-listing-dynamic-container');
export const SELECTLOGCartOrderSummary = () => import('./cart/selectlog-cart-order-summary');
export const SELECTLOGCartPromotionsDisplay = () => import('./cart/selectlog-cart-promotions-display');
export const SELECTLOGCartItemDetails = () => import('./cart/selectlog-cart-item-details');
export const SELECTLOGCheckoutMultiShippingDetails = () =>
  import('./checkout/selectlog-checkout-multi-shipping-details');
export const SELECTLOGCheckoutContinueToPaymentButton = () =>
  import('./checkout/selectlog-checkout-continue-to-payment-button');
export const SELECTLOGProductListingSortResults = () =>
  import('./product-listing/selectlog-product-listing-sort-results');
export const SELECTLOGCategorySearchTermsSummary = () => import('./category/selectlog-category-search-terms-summary');
export const SELECTLOGFooterContainerLinks = () => import('./footer/selectlog-footer-container-links');
export const SELECTLOGFooterLinksList = () => import('./footer/selectlog-footer-links-list');
export const SELECTLOGProfileScheduledOrderHistory = () =>
  import('./profile/selectlog-profile-scheduled-order-history');
export const SELECTLOGCheckoutPaymentsContainer = () => import('./checkout/selectlog-checkout-payments-container');
export const SELECTLOGCheckoutInvoice = () => import('./checkout/selectlog-checkout-invoice');
export const SELECTLOGCheckoutPaymentMethodsContainer = () =>
  import('./checkout/selectlog-checkout-payment-methods-container');
export const SELECTLOGCheckoutContinueToReviewOrderButton = () =>
  import('./checkout/selectlog-checkout-continue-to-review-order-button');
export const SELECTLOGCheckoutReviewOrderContainer = () =>
  import('./checkout/selectlog-checkout-review-order-container');
export const SELECTLOGCheckoutPlaceOrderButton = () => import('./checkout/selectlog-checkout-place-order-button');
export const SELECTLOGShippingInformation = () => import('./checkout/selectlog-checkout-shipping-information');
export const SELECTLOGCheckoutShippingContainer = () => import('./checkout/selectlog-checkout-shipping-container');
export const SELECTLOGProfileOrderDetailsContainer = () =>
  import('./profile/selectlog-profile-order-details-container');
export const SELECTLOGProfilePaymentInformation = () => import('./profile/selectlog-profile-payment-information');
export const SELECTLOGCheckoutReviewOrderPaymentInformation = () =>
  import('./checkout/selectlog-checkout-review-order-payment-information');
export const SELECTLOGProfileScheduledOrdersSummary = () =>
  import('./profile/selectlog-profile-scheduled-orders-summary');
export const SELECTLOGProfileScheduledOrderDetailsContainer = () =>
  import('./profile/selectlog-profile-scheduled-order-details-container');
export const SELECTLOGProfileEditDeleteScheduledOrder = () =>
  import('./profile/selectlog-profile-edit-delete-scheduled-order');
export const SELECTLOGProfileScheduledOrderInformation = () =>
  import('./profile/selectlog-profile-scheduled-order-information');
export const SELECTLOGProfileOrderSummary = () => import('./profile/selectlog-profile-order-summary');
export const SELECTLOGCheckoutScheduledOrder = () => import('./checkout/selectlog-checkout-scheduled-order');
export const SELECTLOGProfilePurchaseListDetailsContainer = () =>
  import('./profile/selectlog-profile-purchase-list-details-container');
export const SELECTLOGProfilePurchaseLists = () => import('./profile/selectlog-profile-purchase-lists');
export const SELECTLOGProfilePurchaseListSummaryInformation = () =>
  import('./profile/selectlog-profile-purchase-list-summary-information');
export const SELECTLOGProfilePurchaseListInformation = () =>
  import('./profile/selectlog-profile-purchase-list-information');
export const SELECTLOGProductListingImage = () =>
  import('./product-listing/selectlog-product-listing-product-result-image');
export const SELECTLOGProductResultName = () =>
  import('./product-listing/selectlog-product-listing-product-result-name');
export const SELECTLOGProductProductImageViewer = () => import('./product/selectlog-product-product-image-viewer');
export const SELECTLOGProfileOrderInformation = () => import('./profile/selectlog-profile-order-information');
export const SELECTLOGProfileRecentOrders = () => import('./profile/selectlog-profile-recent-orders');
export const SELECTLOGProfileOrderHistory = () => import('./profile/selectlog-profile-order-history');
export const SELECTLOGCartContainer = () => import('./cart/selectlog-cart-container');
export const SELECTLOGProfileShippingInformation = () => import('./profile/selectlog-profile-shipping-information');
export const SELECTLOGMenuMobile = () => import('./header/selectlog-menu-mobile');
