export * from './clearOrganizationSellers';
export * from './ordersShippingGroupsSellerB2b';
export const _getOrganizationSellers = () => import('./getOrganizationSellers');
export const _getOrganizationDocs = () => import('./getOrganizationDocs');
export const _getCustomerPaymentMethods = () => import('./getCustomerPaymentMethods');
export const _getExternalPrices = () => import('./getExternalPrices');
export const _getExternalPrice = () => import('./getExternalPrice');
export const _getCNPJInformation = () => import('./getCNPJInformation');
export const _getOrganizationOrders = () => import('./getOrganizationOrders');
export const _getOrganizationCoupons = () => import('./getOrganizationCoupons');
export const _getOrganizationSugestions = () => import('./getOrganizationSugestions');
